html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  width: 100vw;
  min-height: 100vh;
  background-color: White;
}

.page {
  overflow-x: hidden;
  position: relative;
  max-width: 900px;
  height: 100vh;
  margin: auto;
}
