#iconset {
  top: -9999px;
}

.svg-icon {
  &--logo {
    display: block;
    margin: auto;
    width: 88px;
    height: 40px;
    color: #000000;
    fill: #d4f76b;
  }

  &--payment {
    width: 6em;
    height: 3.24em;
    max-width: none;
    aspect-ratio: 100 / 54;
    fill: Black;
  }

  &--logo-bonuses {
    aspect-ratio: 8.8 / 4;
    max-width: 170px;
    display: block;
    margin: 20px auto 0;
    color: White;
    fill: transparent;
  }
}
