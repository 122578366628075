.bonuses-info {
  position: absolute;
  inset: 0 0 auto auto;
  z-index: 1;
  margin: auto;
  width: 42px;
  height: 42px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTAgMThhOCA4IDAgMSAwIDAtMTYuMDAxQTggOCAwIDAgMCAxMCAxOFptLTEuMjUtNS41aC43NXYtMmgtLjc1QS43NDguNzQ4IDAgMCAxIDggOS43NWMwLS40MTYuMzM0LS43NS43NS0uNzVoMS41Yy40MTYgMCAuNzUuMzM0Ljc1Ljc1djIuNzVoLjI1Yy40MTYgMCAuNzUuMzM0Ljc1Ljc1cy0uMzM0Ljc1LS43NS43NWgtMi41YS43NDguNzQ4IDAgMCAxLS43NS0uNzVjMC0uNDE2LjMzNC0uNzUuNzUtLjc1Wk0xMCA2YTEgMSAwIDEgMSAwIDIgMSAxIDAgMCAxIDAtMloiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMiAyaDE2djE2SDJ6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
