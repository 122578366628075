.auth-nav {
  display: flex;
  justify-content: center;
  gap: 0 30px;

  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;

  a {
    color: Black;
    text-decoration: none;
  }

  &__item {
    position: relative;

    &.is-active {
      &::before {
        background: #2962ff;
        position: absolute;
        inset: auto -5px -3px;
        content: "";
        border-radius: 4px;
        height: 4px;
      }
    }
  }
}
