.auth-banner {
  position: relative;

  @include min(tablet-portrait) {
    position: relative;
    min-height: 100vh;
    background-color: #123CDC;
  }

  .svg-icon {
    display: none;

    @include min(tablet-portrait) {
      display: block;
    }
  }

  .headline-small {
    display: none;
    margin: auto auto 16px;
    text-transform: uppercase;
    font-weight: 400;
    color: White;
    max-inline-size: none;
    width: fit-content;

    @include min(tablet-portrait) {
      display: block;
    }
  }

  &__bonuses {
    @include min(tablet-portrait) {
      position: absolute;
      inset: 0 0 auto;
      margin: auto;
      z-index: 1;
      width: 100%;
      height: 51%;

      display: grid;
      place-content: center stretch;
    }
  }

  @include min(tablet-portrait) {
    background-image:
      url('../images/banner_bottom.png'),
      url('../images/banner_top.png');
    background-repeat: no-repeat, no-repeat;
    background-size: max(100%, 1100px), 100%;
    background-position: center bottom, center top;
  }

  @include min(tablet-portrait) {
    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: 103%;
      background-image:
        url('../images/casino_bg_one.png'),
        url('../images/sport_bg_one.png');
      background-repeat: no-repeat, no-repeat;
      background-size:
        min(15vmin, 150px),
        min(16vmin, 160px);
      background-position:
        top 36vmin left -4vmin,
        top 6vmin right;
    }

    &::before {
      pointer-events: none;
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: 103%;
      background-repeat: no-repeat, no-repeat;
      background-size:
        min(23vmin, 230px) min(29vmin, 290px),
        min(16vmin, 160px) min(25.6vmin, 256px);
      background-position:
        bottom 6vmin right,
        bottom 1vmin left 15vmin;
      filter: drop-shadow(0 0 25px rgba(255, 255, 255, 0.85));

      body.geo-ru & {
        background-image:
          url('../images/notebook_ru.png'),
          url('../images/phone_ru.png');
      }

      body.geo-kz & {
        background-image:
          url('../images/notebook_kz.png'),
          url('../images/phone_kz.png');
      }

      body.geo-kz_ru & {
        background-image:
          url('../images/notebook_kz_ru.png'),
          url('../images/phone_kz_ru.png');
      }

      body.geo-az & {
        background-image:
          url('../images/notebook_az.png'),
          url('../images/phone_az.png');
      }
    }
  }
}
