.auth-support {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-medium {
  margin: 0 0 12px;
  font-weight: 500;
  font-size: 14px;
  color: #757575;
}

.btn-support {
  display: grid;
  place-content: center;
  margin: auto;
  width: fit-content;
  height: 38px;
  padding: 0 16px 0 40px;
  border: 1px solid #2962ff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzI5NjJmZiIgZD0iTTE4LjI1NSAyLjIyYy0uMy0uMjU0LS43NzEtLjI5LTEuMjU4LS4wOTVoLS4wMDFjLS41MTIuMjA1LTE0LjUwNCA2LjIwNy0xNS4wNzQgNi40NTItLjEwNC4wMzYtMS4wMDguMzc0LS45MTUgMS4xMjYuMDgzLjY3OC44MS45NTkuODk5Ljk5MWwzLjU1NyAxLjIxOGMuMjM2Ljc4NiAxLjEwNiAzLjY4NCAxLjI5OSA0LjMwMy4xMi4zODYuMzE1Ljg5My42NTguOTk4LjMuMTE2LjYuMDEuNzk0LS4xNDJsMi4xNzQtMi4wMTcgMy41MTEgMi43MzguMDg0LjA1Yy4yMzguMTA1LjQ2Ny4xNTguNjg1LjE1OC4xNjggMCAuMzMtLjAzMi40ODQtLjA5NS41MjYtLjIxNi43MzYtLjcxNy43NTgtLjc3NGwyLjYyMy0xMy42M2MuMTYtLjcyOS0uMDYzLTEuMDk5LS4yNzgtMS4yODFaTTguNiAxMi40bC0xLjIgMy4yLTEuMi00IDkuMi02LjgtNi44IDcuNloiLz48L3N2Zz4=);
  background-position: left 12px center;
  background-repeat: no-repeat;
  color: #2962ff;
  font-size: 16px;
  letter-spacing: .5px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
}
