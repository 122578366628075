.auth-payment {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 450px;
  height: fit-content;

  &__item {

  }
}
