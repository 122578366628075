.euro-bonuses {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 0 10px;
  margin: auto;
  padding: 0 15px;
  max-width: 420px;

  @include min(tablet-portrait) {
    gap: 0 15px;
    max-width: 760px;
  }

  input[type=radio] {
    display: none;
  }

  input#bonus-sport:checked ~ &__sport,
  input#bonus-casino:checked ~ &__casino {
    .bonuses-type {
      background-color: #CCFF66;
      color: Black;

      span {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTIgMTBhOCA4IDAgMCAxIDgtOCA4IDggMCAwIDEgOCA4IDggOCAwIDAgMS04IDggOCA4IDAgMCAxLTgtOFptMTEuNjE5LTEuMzgxYS44NzcuODc3IDAgMCAwIDAtMS4yMzguODc3Ljg3NyAwIDAgMC0xLjIzOCAwTDkgMTAuNzYybC0xLjM4MS0xLjM4YS44NzcuODc3IDAgMCAwLTEuMjM4IDAgLjg3Ny44NzcgMCAwIDAgMCAxLjIzN2wyIDJjLjM0LjM0Ljg5Ny4zNCAxLjIzOCAwbDQtNFoiLz48L3N2Zz4=);
      }
    }
  }

  &__sport {
    position: relative;
    overflow: hidden;
    background-image:
      url('../images/sport_bg_one.png'),
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTU0IiBmaWxsPSJub25lIj48cGF0aCBmaWxsPSIjQzQwMEY1IiBkPSJNNjEuNjYxIDAgMCA3OS43OTVoMzUuMjIyTDEwLjgxNSAxNTRoNS4yNkwxMDAgNDUuMzczSDY0Ljc3OEw3OS43MDYgMEg2MS42NloiLz48L3N2Zz4=),
      linear-gradient(to right, #8300EB, #0E00AE);
    background-repeat:
      no-repeat,
      no-repeat,
      no-repeat;
    background-size: 40%, auto 90%, auto;
    background-position: right -10px bottom -4px, top left 30px, 0 0;
    border-radius: 16px;

    @include min(tablet-portrait) {
      background-position: right -40px bottom -40px, top left 30px, 0 0;
    }
  }

  &__casino {
    position: relative;
    overflow: hidden;
    background-image:
      url('../images/coin.png'),
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTU0IiBmaWxsPSJub25lIj48cGF0aCBmaWxsPSIjMUMwMDhDIiBkPSJNNjEuNjYxIDAgMCA3OS43OTVoMzUuMjIyTDEwLjgxNSAxNTRoNS4yNkwxMDAgNDUuMzczSDY0Ljc3OEw3OS43MDYgMEg2MS42NloiLz48L3N2Zz4=),
      linear-gradient(to right, #8101C1, #B900B2);
    background-repeat:
      no-repeat,
      no-repeat,
      no-repeat;
    background-size: 40%, auto 90%, auto;
    background-position: right -10px bottom -4px, top left 30px, 0 0;
    border-radius: 16px;

    @include min(tablet-portrait) {
      background-position: right -40px bottom -40px, top left 30px, 0 0;
    }
  }
}

.bonuses-type {
  margin: auto 3px 3px;
  min-height: 36px;
  display: grid;
  place-content: center;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  border-radius: 14px;
  border: 1px solid #CCFF66;
  color: White;
  cursor: pointer;
  backdrop-filter: blur(20px);
  user-select: none;

  @include min(tablet-portrait) {
    min-height: 52px;
    font-size: 18px;
    border-radius: 16px;
  }

  span {
    line-height: 1;
    padding-left: 26px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE4IDEwYTggOCAwIDAgMS04IDggOCA4IDAgMCAxLTgtOCA4IDggMCAwIDEgOC04IDggOCAwIDAgMSA4IDhabS04LTYuNWMtMy41OSAwLTYuNSAyLjkxLTYuNSA2LjVzMi45MSA2LjUgNi41IDYuNSA2LjUtMi45MSA2LjUtNi41LTIuOTEtNi41LTYuNS02LjVaIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 20px;
  }
}

.bonuses-text {
  display: grid;
  grid-template: auto auto / 1fr;
  gap: 10px 0;
  place-items: center;
  padding: 40px 0 22px;

  @include min(tablet-portrait) {
    padding: 3vmin 0 2vmin;
  }

  span {
    line-height: 1;
    font-weight: 700;
    color: #BDFF00;

    &:first-child {
      font-size: 18px;
      white-space: nowrap;
      width: fit-content;

      @include min(tablet-portrait) {
        font-size: clamp(18px, calc(1.4vw + 1.4vh), 38px);
      }
    }

    &:last-child {
      font-size: 11px;
      white-space: nowrap;
      width: fit-content;
      text-transform: uppercase;

      @include min(tablet-portrait) {
        font-size: clamp(11px, calc(0.8vw + 0.8vh), 18px);
      }
    }
  }
}
