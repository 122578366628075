.current-bonus {
  position: relative;
  padding-left: 26px;
  font-size: 14px;

  &--casino {
    [class*="casino"] {
      display: none;
    }
  }

  &--sport {
    [class*="sport"] {
      display: none;
    }
  }

  div {
    display: inline;
  }

  span {
    font-weight: 600;
  }

  .bonuses-info {
    position: absolute;
    inset: -11px auto auto -11px;
    filter: invert(100%);
  }
}
