.auth-form {
  margin: auto;
  max-width: 420px;
  padding: 0 15px;

  .text-field-container {
    margin-bottom: 16px;
  }

  .select-option-container {
    margin-bottom: 16px;
  }

  .tickbox {

  }

  [data-ignore="true"] {
    display: none;
  }
}

.auth-rules {
  --tickbox-checkbox-radius: 5px;

  margin: 0;
  padding: 0;
  display: flex;

  label {
    margin-left: -10px;
    font-size: 13px;
    line-height: 1.3;

    span {
      div {
        display: inline-block;
      }
    }

    span:first-child {
      white-space: unset;
      text-overflow: unset;
      overflow: auto;
    }
  }

  a {
    color: #3B7CED;

    &:hover {
      text-decoration: none;
    }
  }
}

.btn-auth {
  color: var(--c-white);
  letter-spacing: .5px;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: #aa00ff;
  border-radius: 44px;
  place-content: center;
  width: 100%;
  min-width: fit-content;
  height: 44px;
  margin: 15px auto;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  display: grid;
  border: none;
}
