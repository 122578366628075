.dialog-content {
  display: none;
  gap: 0;
  width: min(calc(100vw - 40px), 520px);
  min-height: 100px;
  border-radius: 21.5px;
  justify-items: center;
  background-color: White;

  > div {
    padding: 20px 30px;
  }

  .dialog-banner-sport,
  .dialog-banner-casino {
    width: 100%;
    aspect-ratio: 42 / 12;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 16px;
  }

  body.geo-ru & {
    .dialog-banner-sport {
      background-image: url('../images/dialog-banner-sport_ru.png');
    }

    .dialog-banner-casino {
      background-image: url('../images/dialog-banner-casino_ru.png');
    }
  }

  body.geo-kz & {
    .dialog-banner-sport {
      background-image: url('../images/dialog-banner-sport_kz.png');
    }

    .dialog-banner-casino {
      background-image: url('../images/dialog-banner-casino_kz.png');
    }
  }

  body.geo-kz_ru & {
    .dialog-banner-sport {
      background-image: url('../images/dialog-banner-sport_kz_ru.png');
    }

    .dialog-banner-casino {
      background-image: url('../images/dialog-banner-casino_kz_ru.png');
    }
  }

  body.geo-az & {
    .dialog-banner-sport {
      background-image: url('../images/dialog-banner-sport_az.png');
    }

    .dialog-banner-casino {
      background-image: url('../images/dialog-banner-casino_az.png');
    }
  }
}

.dialog-lite {
  &--bonus-sport {
    .dialog-content {
      &--bonus-sport {
        display: grid;
      }

      &--bonus-casino {
        display: none;
      }
    }
  }

  &--bonus-casino {
    .dialog-content {
      &--bonus-sport {
        display: none;
      }

      &--bonus-casino {
        display: grid;
      }
    }
  }
}

.dialog-lite-close-button {
  width: 50px;
  height: 50px;

  &::before {
    position: absolute;
    inset: 0;
    margin: auto;
    content: '';
    background-color: White;
    width: 24px;
    height: 24px;
    border-radius: 100%;
  }

  .svg-icon {
    transform: scale(0.65);
  }
}
