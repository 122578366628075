.euro-auth {
  display: grid;
  grid-template: repeat(7, auto) / 1fr;
  gap: 0;
  min-height: 100%;
  background-color: #F5F5F5;

  @include min(tablet-portrait) {
    grid-template: repeat(5, auto) 1fr / 1.5fr 1fr;
    grid-template-areas:
    'banner logo'
    'banner title'
    'banner nav'
    'banner form'
    'banner support'
    'banner payment';
  }

  .auth-banner {
    padding-top: min(4vmin, 20px);

    @include min(tablet-portrait) {
      padding: 0;
      grid-area: banner;
      background-color: #123CDC;
    }
  }

  .auth-logo {
    padding-top: min(4vmin, 20px);

    @include min(tablet-portrait) {
      grid-area: logo;
    }
  }

  .auth-nav {
    padding-top: min(6vmin, 30px);

    @include min(tablet-portrait) {
      padding-top: min(4vmin, 20px);
      grid-area: title;
    }
  }

  .auth-tabs {
    padding-top: min(7vmin, 35px);

    @include min(tablet-portrait) {
      padding-top: min(6vmin, 30px);
      grid-area: nav;
    }
  }

  .auth-form {
    padding-top: min(4vmin, 20px);

    @include min(tablet-portrait) {
      grid-area: form;
    }
  }

  .auth-support {
    padding-top: min(4vmin, 20px);

    @include min(tablet-portrait) {
      grid-area: support;
    }
  }

  .auth-payment {
    padding-top: min(6vmin, 30px);
    padding-bottom: min(6vmin, 30px);

    @include min(tablet-portrait) {
      grid-area: payment;
    }
  }
}
