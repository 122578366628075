.auth-tabs {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: auto;
  padding: 0 15px;
  width: 100%;
  max-width: 420px;

  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;

  &__item {
    display: flex;
    align-items: center;
    padding: 7px 12px;
    border-radius: 10px;
    user-select: none;

    &:not(.is-active) {
      cursor: pointer;
    }

    &.is-active {
      background-color: #2962ff;
      color: #ffffff;
    }
  }
}
