.control-title {
  margin: 0 0 -20px;
  padding: 0 5vmin;
  font-size: 3.8em;
  font-weight: 700;
  line-height: 1.2;
  color: var(--c-white);
  text-align: center;
  text-wrap: balance;
  max-inline-size: 30ch;
}
